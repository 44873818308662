<template>
  <div class="custom-number-filter">
    <div v-if="params.filterName" class="custom-number-filter__title">
      {{ params.filterName }}
    </div>
    <div>
      <custom-input
        name="custom-number-filter"
        type="number"
        v-model="filterValue"
        placeholder="Введите количество"
        :inputWidth="100"
      />
    </div>
    <button v-if="filterValue" @click="filterValue = ''" class="btn btn-w btn-sm">Очистить</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import CustomInput from "../../Forms/Fields/CustomInput.vue";
import { watch } from "@vue/runtime-core";
export default {
  components: { CustomInput },
  setup(props) {
    const filterValue = ref(null);

    const doesFilterPass = (params) => {
      const { api, colDef, column, columnApi, context } = props.params;
      const { node } = params;

      let passed = true;
      const value = props.params.valueGetter({
        api,
        colDef,
        column,
        columnApi,
        context,
        data: node.data,
        getValue: (field) => node.data[field],
        node,
      });

      if (value < filterValue.value) {
        passed = false;
      }

      return passed;
    };

    const isFilterActive = () => {
      return filterValue.value != null && filterValue.value !== "";
    };

    const getModel = () => {
      if (!isFilterActive()) {
        return null;
      }

      return { value: filterValue.value };
    };

    const setModel = (model) => {
      filterValue.value = model == null ? null : model.value;
    };

    watch(filterValue, () => {
      props.params.filterChangedCallback();
    });

    return {
      filterValue,
      doesFilterPass,
      isFilterActive,
      getModel,
      setModel,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-number-filter {
  padding: 5px 5px 15px 20px;
  background-color: white;
  border: 1px solid var(--cream-color);
  border-radius: 3px;

  .form-group{
    margin: 10px 0;
  }

  &__title {
    font-size: 15px;
    font-weight: 400;
    padding-top: 8px;
  }
}
</style>