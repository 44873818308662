<template>
  <div class="flex justify-content-start group-filters visible-sm">
    <custom-input
      :delay="800"
      name="staff-passings-sort"
      label="Название"
      :inputWidth="100"
      cleanable
      v-model="searchParams.nameField"
    />
    <custom-select-2
      label="Куратор"
      name="owner"
      v-model="searchParams.curatorField"
      :settings="usersAjax"
    />
  </div>
  <div v-if="groups">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      style="width: 100%"
      domLayout="autoHeight"
      @selection-changed="onRowSelected"
      @grid-ready="onGridReady"
      @filterChanged="filterChanged"
    ></ag-grid-vue>

    <v-pagination
      v-if="gridApi"
      v-model="page"
      @update:modelValue="changePage"
      :pages="gridApi.paginationGetTotalPages()"
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="gridApi && gridApi.paginationGetTotalPages()"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
        {{ gridApi.paginationGetRowCount() }}
      </p>
      <p>
        Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
        {{ gridApi.paginationGetTotalPages() }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "@/components/Tables/CellRenderers/EditRenderer.vue";
import TextLinkRenderer from "@/components/Tables/CellRenderers/TextLinkRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import CustomNumberFromFilter from "@/components/Tables/FiltersComponents/CustomNumberFromFilter.vue";
import groupTableColumns from "./groupTableColumns";
import useAjaxSelect from "@/mixins/useAjaxSelect";
export default {
  name: "group-table",
  emits: ["groupSelected"],
  components: {
    VPagination,
    AgGridVue,
    EditRenderer,
    TextLinkRenderer,
    Preloader,
    CustomInput,
    CustomSelect2,
    CustomTextFilter,
    CustomNumberFromFilter,
  },
  setup(props, context) {
    const store = useStore(),
      gridApi = ref(null),
      columnApi = ref(null);
    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
      countOnPage.value = 11;
      countOnPage.value = 10;
    };
    const countOnPage = ref(15),
      page = ref(1),
      isPreloader = ref(false),
      searchParams = reactive({
        nameField: "",
        curatorField: "Не выбрано",
      });
    const groups = computed(() => store.state.group.groups),
      rowData = computed(() => {
        return groups.value.map((group) => {
          return {
            id: group.id,
            name: group.name,
            edit: group.id,
            owner: group.owner.name,
            usersCount: group.users_count,
          };
        });
      });

    const columnDefs = reactive(groupTableColumns);
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };
    const { usersAjax } = useAjaxSelect();

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };
    const onRowSelected = () => {
      const selectedNodes = gridApi.value.getSelectedNodes();
      context.emit(
        "groupSelected",
        selectedNodes.length ? selectedNodes[0].data.id : null
      );
    };

    watch(searchParams, () => {
      if (
        searchParams.nameField.length >= 3 ||
        searchParams.curatorField !== "Не выбрано"
      ) {
        gridApi.value.setQuickFilter(
          `${
            searchParams.nameField.length >= 3 ? searchParams.nameField : ""
          } ${
            searchParams.curatorField !== "Не выбрано"
              ? searchParams.curatorField
              : ""
          }`
        );
      } else {
        gridApi.value.setQuickFilter(null);
      }
      page.value = 1;
    });

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    onBeforeMount(() => {
      store.dispatch("group/getGroups");
    });

    const filterChanged = () => {
      page.value = 2;
      page.value = 1;
    };

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      groups,
      searchParams,
      usersAjax,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      filterChanged,
      onRowSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  min-width: 420px;
}

.group-filters {
  // display: none;
}

@media (max-width: 1251px) {
  .form-group {
    min-width: 100%;
  }
  .group-filters {
    flex-direction: column;
  }
}
</style>